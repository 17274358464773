<template>
  <div>
    <NavView></NavView>
    <div class="header">
      <el-carousel height="43vw" :autoplay="true">
        <el-carousel-item>
          <img class="header-bg-img" src="@/assets/imgs/header_bg1.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="header-bg-img" src="@/assets/imgs/header_bg2.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="header-bg-img" src="@/assets/imgs/header_bg3.png" />
        </el-carousel-item>
        <el-carousel-item>
          <img class="header-bg-img" src="@/assets/imgs/header_bg4.png" />
        </el-carousel-item>
      </el-carousel>
      <div class="header-desc">
        <div class='slogan-row-1'>
          <img class='slogan1' src="@/assets/imgs/slogan1.png"/>
          <img class='slogan2' src="@/assets/imgs/slogan2.png"/>
        </div>
        <img class='slogan-row-2' src="@/assets/imgs/slogan3.png" />
        <div class='header-btn-area'>
          <div class="header-entry entry-btn" @click="goToPortal">即刻使用&gt;</div>
          <div class="header-entry entry-btn" @click="goToForm">加速商业&gt;</div>
        </div>
      </div>
      <div class="header-transition"></div>
    </div>
    <div class="content">
      <img class='title' src='@/assets/imgs/contentSlogan.png'/>
      <div class='individual'>
        <div class='module-title'>
          <span>个人用户</span>
          <span @click="goToPortal">即刻使用&nbsp;&gt;</span>
        </div>
        <div class='individual-row first'>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>代码审查专家</div>
              <div class='desc'>帮助程序员提高代码质量和性能</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>小红书种草博主</div>
              <div class='desc'>将为您提供具有小红书风格的文案</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>创意编剧</div>
              <div class='desc'>帮助你创作出吸引人的电影或网络剧剧本</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>模拟面试官</div>
              <div class='desc'>提出问题并等待答案，模拟真实的工作面试</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>地道的英语外教</div>
              <div class='desc'>可以帮助你修正文章中的语法问题，练习你的口语</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>财务顾问</div>
              <div class='desc'>帮助你处理各种财务相关的任务，包括财务报告编制、预算管理等</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>旅行规划师</div>
              <div class='desc'>专门帮助用户制定旅行计划，提供旅游景点建议，并介绍景点的历史文化背景</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>会议纪要助手</div>
              <div class='desc'>帮助你从口语化的会议录音稿中提取关键信息，生成规范、准确的会议纪要</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>抖音口播文案专家</div>
              <div class='desc'>擅长创作吸引人的推销语，能帮助你提升商品销售</div>
            </div>
          </div>
          <div class='individual-col'>
            <div class='info'>
              <div class='name'>广告创意人</div>
              <div class='desc'>帮助你创作出吸引人的广告语，并提供有效的广告策略和建议</div>
            </div>
          </div>
        </div>
      </div>
      <div class='commerce'>
        <div class='module-title'>
          <span>商业用户</span>
          <span @click="goToForm">现在申请&nbsp;&gt;</span>
        </div>
        <div class="panel">
          <div class="panel-content">
            <div class="scene-desc-context">
              <div class='scene-desc-top'>
                <div class="scene-desc-title">多场景解决方案</div>
                <div class="scene-desc">FLIG.ai提供了多种大模型能力及丰富的应用模板，您的团队可以基于此轻松创造不同垂类场景的AI原生应用</div>
              </div>
              <div class="scene-tag-item">
                <div class="scene-tag-title">医疗</div>
                <div class="scene-tag-features">
                  智能导诊<br/>远程问诊
                </div>
              </div>
              <div class="scene-tag-item">
                <div class="scene-tag-title">社交</div>
                <div class="scene-tag-features">
                  <div class="scene-tag-feature">情感对话</div>
                  <div class="scene-tag-feature">数字分身</div>
                </div>
              </div>
              <div class="scene-tag-item">
                <div class="scene-tag-title">创作</div>
                <div class="scene-tag-features">
                  <div class="scene-tag-feature">营销文案</div>
                  <div class="scene-tag-feature">新闻稿件</div>
                </div>
              </div>
              <div class="scene-tag-item">
                <div class="scene-tag-title">政务</div>
                <div class="scene-tag-features">
                  <div class="scene-tag-feature">文书撰写</div>
                  <div class="scene-tag-feature">政策解读</div>
                </div>
              </div>
              <div class="scene-tag-item">
                <div class="scene-tag-title">教育</div>
                <div class="scene-tag-features">
                  <div class="scene-tag-feature">教案生成</div>
                  <div class="scene-tag-feature">亲子伴读</div>
                </div>
              </div>
              <div class="scene-tag-item">
                <div class="scene-tag-title">办公</div>
                <div class="scene-tag-features">
                  <div class="scene-tag-feature">数据分析</div>
                  <div class="scene-tag-feature">办公组件</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img class='developer-title' src='@/assets/imgs/developers_title.png'/>
      <div class='develop'>
        <div class='module-title'>
          <span>主创</span>
          <span> </span>
        </div>
        <div class='people-list'>
          <div class='info start'>
            <div class='name'>Bill P</div>
            <div class='feature'>做AI的</div>
          </div>
          <div class='info'>
            <div class='name'>Ethan Fan</div>
            <div class='feature'>绝对领域</div>
          </div>
          <div class='info'>
            <div class='name'>Peter Li</div>
            <div class='feature'>背锅侠</div>
          </div>
          <div class='info end'>
            <div class='name'>XB</div>
            <div class='feature'>临时工</div>
          </div>
          <div class='info start'>
            <div class='name'>BZ Wei</div>
            <div class='feature'>夹沟师</div>
          </div>
          <div class='info'>
            <div class='name'>Frog Fu</div>
            <div class='feature'>播小片片的</div>
          </div>
          <div class='info'>
            <div class='name'>Phillip Z</div>
            <div class='feature'>Bug之父</div>
          </div>
          <div class='info end'>
            <div class='name'>Cloud Wu</div>
            <div class='feature'>正式工</div>
          </div>
          <div class='info start'>
            <div class='name'>Joe Gao</div>
            <div class='feature'>1688 king</div>
          </div>
          <div class='info'>
            <div class='name'>Richard Zhong</div>
            <div class='feature'>硅疝人</div>
          </div>
          <div class='info'>
            <div class='name'>Eric Yao</div>
            <div class='feature'>算账的</div>
          </div>
          <div class='info'>
            <div class='name'>Max Zhao</div>
            <div class='feature'>认真冲的</div>
          </div>
          <div class='info'>
            <div class='name'>Wenwu Jiang</div>
            <div class='feature'>大芯骗</div>
          </div>
        </div>
        <div class='module-title' style="margin-top: 75px">
          <span>社区，商务，摇人</span>
          <span> </span>
        </div>
        <div class='people-list business'>
          <div class='info'>
            <div class='name'>Cassie Cao</div>
            <div class='feature'>大表姐</div>
          </div>
          <div class='info'>
            <div class='name'>haha</div>
            <div class='feature'>全能战士</div>
          </div>
        </div>
      </div>
      <div class='line-title'>
        <img src='@/assets/imgs/line_icon.png'/>
        <img src='@/assets/imgs/line_title.png'/>
      </div>
      <div class='line'>
        任何技术性骚扰请发邮件至<span>support@flig.ai</span>
      </div>
    </div>
    <footer>
      <div class='link'>
        <span>用户协议</span>
        <span>隐私条款</span>
      </div>
      <div>FligAI中国 © 2023. All rights reserved. 备案号：沪ICP备2023017781号</div>
    </footer>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import NavView from '@/components/NavView'
import '@/styles/homepage.scss'

const router = useRouter()
const goToPortal = () => {
  ElMessage.warning('功能开发中，敬请期待!')
}

const goToForm = () => {
  router.push('/applicationForm')
}
</script>
