<template>
  <div class="application-form">
    <NavView></NavView>
    <img class="bk-img" src="@/assets/imgs/header_bg1.png" />
    <div class="form-content">
      <div class="header-transition"></div>
      <div class="title">邀请体验说明</div>
      <div class="desc">FLIG.ai平台目前已开放申请通道。如您有任何相关业务需要，欢迎填写表单进行申请，我们会根据您的公司信息和应用场景尽快做出处理，并在评估通过后与您联系。</div>
      <el-form ref="ruleFormRef" class="form" label-position="top" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="您的邮箱(我们会通过邮箱通知您注册的结果）" :required="true" prop="email">
          <el-input v-model="form.email" maxlength="50" placeholder="请输入企业邮箱，会作为后续登录的账号"/>
        </el-form-item>
        <el-form-item label="公司名称" :required="true" prop="company_name">
          <el-input v-model="form.company_name" maxlength="100" placeholder="请输入完整的公司名称"/>
        </el-form-item>
        <el-form-item label="姓名" :required="true" prop="real_name">
          <el-input v-model="form.real_name" maxlength="20" placeholder="请输入真实姓名"/>
        </el-form-item>
        <el-form-item label="联系电话" :required="true" prop="mobile">
          <el-input v-model="form.mobile" maxlength="20" placeholder="请输入联系电话"/>
        </el-form-item>
        <el-form-item label="选择公司类型" :required="true" prop="company_type">
          <el-radio-group
            v-model="form.company_type"
            fill="#C76DFF"
            text-color="#C76DFF"
          >
            <el-radio
              v-for="item in options.company_type"
              :label="item.value"
              :value="item.value"
              :key="item.value"
              size="large"
            >{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="业务场景" :required="true" prop="scenes">
          <el-input type="textarea" v-model="form.scenes" maxlength="100" :rows="5" placeholder="描述业务使用场景"/>
        </el-form-item>
        <el-form-item label="公司主页">
          <el-input v-model="form.company_site" maxlength="300" placeholder="请输入完整的公司网址"/>
        </el-form-item>
        <el-form-item label="该业务场景是服务于企业内部用户还是外部用户" :required="true" prop="serve_scope">
          <el-radio-group
            v-model="form.serve_scope"
          >
            <el-radio
              v-for="item in options.serve_scope"
              :label="item.value"
              :value="item.value"
              :key="item.value"
              size="large"
            >{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="该业务的预期日访问是什么级别" :required="true" prop="daily_qps">
          <el-radio-group
            v-model="form.daily_qps"
          >
            <el-radio
              v-for="item in options.daily_qps"
              :label="item.value"
              :value="item.value"
              :key="item.value"
              size="large"
            >{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="tooltip">您点击“提交”的行为意味着您同意我们收集您的信息用于评估您是否可参加我们的体验，并同意我们通过您填写的信息与您取得联系。如您未通过我们的评估，或您向我们提出相关要求，我们将立即删除您提交的信息。如果您通过评估，则我们将在您注销平台账户的时候销毁您提交的信息。</div>
        <el-form-item class="button">
          <el-button type="primary" size="large" @click="onSubmit">确认提交&gt;</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref } from 'vue'
import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import NavView from '../components/NavView'

const rules = {
  mobile: [
    { required: true, message: "请输入联系电话！", trigger: 'blur'},
    {
      validator: (rule, value, callback) => {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的联系电话！'))
        }
      },
      message: "请输入正确的联系电话！",
      trigger: 'blur'
    },
  ],
  real_name: [{ required: true, message: "请输入真实姓名！"}],
  email: [{ required: true, message: "请输入联系邮箱！", trigger: 'blur'},
    {
      validator: (rule, value, callback) => {
        const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的联系邮箱！'))
        }
      },
      message: "请输入正确的联系邮箱！",
      trigger: 'blur'
    },
  ],
  company_name: [{ required: true, message: "请输入企业名称！"}],
  company_type: [{ required: true, message: "请选择企业类型！"}],
  serve_scope: [{ required: true, message: "请选择服务类型！"}],
  daily_qps: [{ required: true, message: "请选择日访问量！"}],
  scenes: [{ required: true, message: "请描述业务使用场景！"}],
  company_site: [{ required: false }],
}
const form = reactive({
  mobile: '',
  real_name: '',
  email: '',
  company_name: '',
  company_type: 0,
  serve_scope: 0,
  daily_qps: 0,
  scenes: '',
  company_site: '',
})

const options = {
  company_type: [
    { value: 0, label: '初创企业' },
    { value: 1, label: '小微企业' },
    { value: 2, label: '中型公司' },
    { value: 3, label: '大型公司' },
    { value: 4, label: '国有企业/政府部门' },
  ],
  serve_scope: [
    { value: 0, label: '内部' },
    { value: 1, label: '外部' },
  ],
  daily_qps: [
    { value: 0, label: '百' },
    { value: 1, label: '千' },
    { value: 2, label: '万' },
    { value: 3, label: '十万' },
    { value: 4, label: '百万' },
    { value: 5, label: '千万' },
    { value: 6, label: '一亿及以上' },
  ],
}
const service = axios.create({
  baseURL: 'http://www.fligai.com'
  // baseURL: 'http://localhost:8082'
})

const ruleFormRef = ref()
const onSubmit = () => {
  if (!ruleFormRef.value) return
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      const loading = ElLoading.service({
        lock: true,
        text: '申请提交中...',
        background: 'rgba(0, 0, 0, 0.8)',
      })
      service.post('/api/apply', form)
        .then(function (response) {
          loading.close()
          const res = response.data
          const { code, message } = res
          if (!code) {
            ElMessage.success('申请成功！')
          } else {
            ElMessage.error(message || '网络错误，请稍后重试')
          }
        })
        .catch(function () {
          loading.close()
          ElMessage.error('网络错误，请稍后重试')
        });
    } else {
      ElMessage.error('请完整的填写表单！')
    }
  })
}
</script>
<style lang="scss" scoped>
.application-form {
  position: relative;
  height: 100vh;
  overflow-y: auto;
  .bk-img {
    width: 100%;
    position: absolute;
    top: 48px;
    z-index: -1;
  }
}
.bk {
  position: absolute;
  height: 300px;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.form-content {
  background: #131313;
  padding: 0 147px;
  color: white;
  margin-top: 300px;
  position: relative;

  @media screen and (max-width: 500px) {
    .title {
      font-size: 18px;
    }
    .desc {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 501px){
    .title {
      font-size: 20px;
    }
    .desc {
      font-size: 16px;
    }
  }

  .desc {
    margin: 20px 0;
    color: #7E7E7E;
  }

  ::v-deep(.form) {
    margin-top: 50px;
    padding-bottom: 50px;

    .el-form-item__label::before {
      color: #BB65FF !important;
    }

    .el-input__wrapper, .el-textarea__inner {
      background: #3D3D3D;
      box-shadow: 0 0 0 1px #3D3D3D inset;
    }

    @media screen and (max-width: 500px) {
      .el-input__wrapper {
        height: 30px;
        font-size: 12px;
      }
    }

    @media screen and (min-width: 501px){
      .el-input__wrapper {
        height: 48px;
        font-size: 14px;
      }
    }

    .el-textarea__inner {
      min-height: 166px !important;
      color: white;
    }

    .el-input__inner {
      color: white;
    }

    .el-form-item__label {
      color: white;
    }

    .el-form-item {
      margin-bottom: 45px;
    }

    .el-radio__input is-checked {

    }

    .button {
      .el-form-item__content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .header-transition {
    height: 188px;
    width: 100%;
    background: linear-gradient(rgba(19, 19, 19, 0), rgba(19, 19, 19, 1));
    position: absolute;
    top: -188px;
    left: 0;
    z-index: 0;
  }

  .tooltip {
    color: #7E7E7E;
    font-size: 16px;
    margin-bottom: 45px;
  }
}
</style>
