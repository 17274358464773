import { createApp } from 'vue'
import router from '@/router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './styles/app.scss'
import App from './App.vue'
import './styles/element.var.scss'

const app = createApp(App).use(router).use(ElementPlus).mount('#app')
console.log(app)
